import { Controller } from '@hotwired/stimulus';
import { DirectUpload } from "@rails/activestorage"
import { APIClient } from '../../lib/api_client';
import { makeElement } from '../../lib/utils';

export default class extends Controller {
  static targets = [
    'imageContainer',
    'emptyImageContainer'
  ];

  static values = {
    uploadUrl: String,
    tileTemplate: String
  };

  connect() {
    this.apiClient = new APIClient();
  }

  newImages(event) {
    const { target } = event;

    if (target.files.length < 1) {
      return;
    }

    const galleryImages = target.files;

    for (const image of galleryImages) {
      const uploader = new DirectUpload(image, this.uploadUrlValue);

      uploader.create((error, blob) => {
        if (error) {
          console.error(error);
        } else {
          this.displayImage(blob);
        }
      });
    }
  }

  async displayImage(blob) {
    try {
      const imageUrl = await this.apiClient.images.fetchUrl(blob.signed_id);

      this.emptyImageContainerTarget.classList.add('d-none');
      this.imageContainerTarget.classList.remove('d-none');

      const newImageTile = makeElement(this.tileTemplateValue);

      newImageTile.querySelector('img').src = imageUrl;
      newImageTile.querySelector('input').value = blob.signed_id;

      this.imageContainerTarget.appendChild(newImageTile);
    } catch (error) {
      console.error(error);
    }
  }

  removeImage(event) {
    const { target } = event;

    target.closest('div[data-role="gallery-image-tile"]').remove();
  }
};
