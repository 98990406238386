import { Controller } from '@hotwired/stimulus';
import { makeElement } from '../../lib/utils';

export default class extends Controller {
  static targets = [
    'faqsContainer'
  ];

  static values = {
    template: String,
    ordinal: Number
  };

  addFAQ(event) {
    const template = this.templateValue.replace(/idx/g, this.ordinalValue);
    const newFAQ = makeElement(template);

    this.faqsContainerTarget.appendChild(newFAQ);

    this.ordinalValue++;
  }

  removeFAQ(event) {
    const faq = event.target.closest('div[data-role="faq"]')

    faq.classList.add('d-none');
    faq.querySelector('input[name*="_destroy"]').value = 1;
  }
};