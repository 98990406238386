import { Controller } from '@hotwired/stimulus';

export default class extends Controller {
  static targets = ['frame'];

  static values = {
    'src': String
  }

  connect() {
    const currentTime = new Date().toISOString();

    this.frameTarget.src = `${this.srcValue}?t=${currentTime}`;
  }
}