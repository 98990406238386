import { Controller } from "@hotwired/stimulus";

import { Calendar } from '@fullcalendar/core';
import rrulePlugin from '@fullcalendar/rrule';
import dayGridPlugin from '@fullcalendar/daygrid';
import listPlugin from '@fullcalendar/list';
import interactionPlugin from '@fullcalendar/interaction';

import { positionElementBesideElement } from "../lib/utils";
import { isNewEvent, buildNewEvent } from "../lib/events/utils";
import { buildNewEventModal } from "../lib/events/builders/new_event_modal";

export default class extends Controller {
  static targets = ['container'];
  static values = {
    'tz': String,
    'eventsFeedUrl': String,
    'newEventModalTemplate': String,
  };

  connect() {
    // Get the current timezone of the browser
    this.browserTimeZone = Intl.DateTimeFormat().resolvedOptions().timeZone;

    this.calendar = new Calendar(this.containerTarget, {
      timeZone: 'local',
      plugins: [rrulePlugin, dayGridPlugin, listPlugin, interactionPlugin],
      initialView: 'dayGridMonth',
      headerToolbar: {
        left: 'prev,next today',
        center: 'title',
        right: 'dayGridMonth,timeGridWeek,listWeek'
      },
      height: '100vh',
      events: this.eventsFeedUrlValue,
      dateClick: this.addNewEvent.bind(this),
      datesSet: this.pushHistory.bind(this),
      eventDidMount: this.addNewEventModal.bind(this),
      eventClick: this.addViewEventModal.bind(this),
    });

    if (this.startDate) {
      this.calendar.gotoDate(this.startDate);
    }

    this.calendar.render();
  }

  addNewEvent(info) {
    // This method is called whenever a user clicks on a date in the calendar.

    const { dateStr } = info;

    if (this.newEvent && dateStr === this.newEvent.start) return;

    this.clearPendingEvents();

    this.newEvent = buildNewEvent(dateStr, this.browserTimeZone);
    this.calendar.addEvent(this.newEvent);
  }

  addNewEventModal(arg) {
    // This method is called once after an event is added to the calendar.

    const { el, event } = arg;

    // Only execute the logic below if we're mounting a new event that the
    // user will creating with our new event modal flow.
    if (!isNewEvent(event)) return;

    // We only allow one active modal on the screen at any time, so we want
    // to make sure we're removing any already active modal flows on the page.
    this.hideOpenModals();

    const modal = buildNewEventModal(
      this.newEvent,
      this.newEventModalTemplateValue,
      {
        returnToDate: this.startDate
      }
    );

    positionElementBesideElement(modal, el, this.element);
  }

  addViewEventModal(info) {
    this.clearPendingEvents();
    this.hideOpenModals();

    info.jsEvent.preventDefault();

    const eventUrl = new URL(info.event.url, window.location.origin);
    eventUrl.searchParams.set('tz', this.browserTimeZone);

    Turbo.visit(eventUrl.pathname + eventUrl.search, { frame: 'show-event' });
  }

  hideOpenModals() {
    // Remove any existing event details modal
    const showEventModal = document.getElementById('showEventModal');
    const newEventModal = document.getElementById('newEventModal');

    if (showEventModal) showEventModal.remove();
    if (newEventModal) newEventModal.remove();
  }

  clearPendingEvents() {
    if (!this.newEvent) return;

    this.calendar.getEventById(this.newEvent.id).remove();
    this.newEvent = null;
  }

  get startDate() {
    const params = new URLSearchParams(window.location.search);

    return params.get('after');
  }

  pushHistory(dateInfo) {
    const { start } = dateInfo;
    const currentMonthStr = this.getCurrentMonthFromRangeStart(start);

    const url = new URL(window.location.href);

    url.searchParams.set('after', currentMonthStr);

    history.replaceState(history.state, '', url);
  }

  getCurrentMonthFromRangeStart(startRange) {
    const currentMonth = new Date(startRange.getTime());

    if (currentMonth.getDate() <= 15) {
      currentMonth.setDate(1);
    } else {
      currentMonth.setDate(1);
      currentMonth.setMonth(currentMonth.getMonth() + 1);
    }

    // Format the currentMonth date into a YYYY-MM-DD string
    const year = currentMonth.getFullYear();
    const month = String(currentMonth.getMonth() + 1).padStart(2, '0'); // +1 because getMonth() is 0-indexed
    const day = String(currentMonth.getDate()).padStart(2, '0');

    return `${year}-${month}-${day}`;
  }
}