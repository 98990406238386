import { ImageAPI } from "./apis/image_api";
import { OnboardTasksAPI } from "./apis/onboard_tasks_api";
import { RegistrationsAPI } from "./apis/registrations_api";
import { EventRegistrationsAPI } from "./apis/event_registrations_api";

class APIClient {
  constructor() {
    const csrfToken = $('meta[name="csrf-token"]').attr('content');

    this.onboard_tasks = new OnboardTasksAPI(csrfToken);
    this.registrations = new RegistrationsAPI(csrfToken);
    this.images = new ImageAPI(csrfToken);
    this.eventRegistrations = new EventRegistrationsAPI(csrfToken);
  }
}

export { APIClient };