
const fireConfetti = (confetti) => {
  const _fire = (particleRatio, opts) => {
    confetti(Object.assign({ zIndex: 2000 }, { origin: { y: 0.7 } }, opts, {
      particleCount: Math.floor(200 * particleRatio)
    }));
  }

  _fire(0.25, { spread: 26, startVelocity: 55, });
  _fire(0.2, { spread: 60, });
  _fire(0.35, { spread: 100, decay: 0.91, scalar: 0.8 });
  _fire(0.1, { spread: 120, startVelocity: 25, decay: 0.92, scalar: 1.2 });
  _fire(0.1, { spread: 120, startVelocity: 45, });
};

export { fireConfetti }