import { EmboltAPI } from "./embolt_api";

const FETCH_IMAGE_URL = '/api/v1/active_storage/blobs/:id';

class ImageAPI extends EmboltAPI {
  async fetchUrl(signed_id) {
    const url = FETCH_IMAGE_URL.replace(':id', signed_id);

    const response = await fetch(url, {
      method: 'GET',
      headers: this.apiHeaders,
    });

    if (!response.ok) {
      throw new Error(response);
    }

    const data = await response.json();

    return data.url;
  }
}

export { ImageAPI };