import { EmboltAPI } from "./embolt_api";

const VALIDATE_EVENT_REGISTRATIONS_URL = '/api/v1/events/:event_id/event_registrations/validate';
const CREATE_EVENT_REGISTRATIONS_ORDER_URL = '/api/v1/events/:event_id/event_tickets/:id/orders';
const BATCH_CREATE_EVENT_REGISTRATIONS_URL = '/api/v1/events/:event_id/event_registrations/batch';

class EventRegistrationsAPI extends EmboltAPI {
  async validate({ eventId, formData }) {
    const url = VALIDATE_EVENT_REGISTRATIONS_URL.replace(':event_id', eventId);
    const payload = new URLSearchParams(formData).toString();

    const response = await fetch(url, {
      method: 'POST',
      body: payload,
      headers: {
        ...this.apiHeaders,
        'Content-Type': 'application/x-www-form-urlencoded',
      },
    });

    return response;
  }

  async createOrder({ eventId, eventTicketId, eventTicketQuantity }) {
    const url = CREATE_EVENT_REGISTRATIONS_ORDER_URL
      .replace(':event_id', eventId)
      .replace(':id', eventTicketId)

    const response = await fetch(url, {
      method: 'POST',
      body: JSON.stringify({
        quantity: eventTicketQuantity
      }),
      headers: this.apiHeaders
    });

    return response
  }

  async batch({ eventId, formData, orderId }) {
    const url = BATCH_CREATE_EVENT_REGISTRATIONS_URL.replace(':event_id', eventId);
    const payload = new URLSearchParams(formData).toString();
    const encodedConfirmationField = encodeURIComponent('event_registrations[confirmation_id]');
    const encodedConfirmationValue = encodeURIComponent(orderId);

    const confirmationId = `${encodedConfirmationField}=${encodedConfirmationValue}`;
    const appendedFormData = `${payload}&${confirmationId}`;

    const response = await fetch(url, {
      method: 'POST',
      body: appendedFormData,
      headers: {
        ...this.apiHeaders,
        'Content-Type': 'application/x-www-form-urlencoded',
      },
    });

    return response;
  }
}

export { EventRegistrationsAPI };