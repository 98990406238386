import { Controller } from '@hotwired/stimulus';

export default class extends Controller {
  static targets = [
    'buyTicketsLink',
    'summaryTicketName',
    'summaryTicketQuantity',
    'summaryTicketSubTotal',
    'summaryTicketTotal',
    'ticketIdInput',
    'ticketQuantityInput',
    'quantitySelect',
  ];

  static values = {
    'currencyCode': String,
    'selectedTicket': Object,
  }

  selectTicket(event) {
    const { params } = event;
    const { name, priceCents, purchaseQuantity } = params;
    const id = event.currentTarget.value;

    const { quantity } = this.selectedTicketValue;

    this.selectedTicketValue = {
      id,
      name,
      priceCents,
      purchaseQuantity,
      quantity: (quantity > purchaseQuantity) ? purchaseQuantity : quantity
    };
  }

  selectQuantity(event) {
    const quantity = event.currentTarget.value;

    this.selectedTicketValue = {
      ...this.selectedTicketValue,
      quantity
    }
  }

  selectedTicketValueChanged() {
    const { id, name, quantity, purchaseQuantity, priceCents } = this.selectedTicketValue;

    const formattedPrice = this.formatPrice(priceCents * quantity);

    this.summaryTicketNameTarget.innerText = name;
    this.summaryTicketQuantityTarget.innerText = `(x${quantity})`;
    this.summaryTicketSubTotalTarget.innerText = formattedPrice;
    this.summaryTicketTotalTarget.innerText = formattedPrice;

    this.ticketIdInputTarget.value = id;
    this.ticketQuantityInputTarget.value = quantity;

    this.updateQuantitySelectOptions(quantity, purchaseQuantity);
  }

  // Format price in cents to currency symbol and decimal
  // with at most two decimal places
  formatPrice(priceCents) {
    const price = priceCents / 100;
    const formattedPrice = price.toLocaleString('en-US', {
      style: 'currency',
      currency: this.currencyCodeValue,
      minimumFractionDigits: 2,
      maximumFractionDigits: 2,
    });

    return `${formattedPrice} ${this.currencyCodeValue}`;
  }

  updateQuantitySelectOptions(quantity, purchaseQuantity) {
    Array.from(this.quantitySelectTarget.options).forEach(option => {
      if (parseInt(option.value) > purchaseQuantity) {
        option.disabled = true;
      } else {
        option.disabled = false;
      }
    });

    this.quantitySelectTarget.selectedIndex = quantity - 1;
  }
}