import { Controller } from "@hotwired/stimulus"
import createTableFlowImporter from "@tableflow/js";

export default class extends Controller {
  static IMPORTER_ID = "fa1ae397-756e-456f-ac2e-b38262910dc2";
  static values = {
    formId: Number,
    formTierId: Number,
    template: Object
  };
  static targets = ['continueButton', 'container'];

  openImporter() {
    this.bulkImportContainer = document.createElement('div');
    this.bulkImportContainer.id = 'bulkImportContainer';
    document.body.appendChild(this.bulkImportContainer);

    this.importer = createTableFlowImporter({
      elementId: '#bulkImportContainer',
      importerId: this.constructor.IMPORTER_ID,
      template: this.templateValue,
      onComplete: this.importRegistrations.bind(this),
      onRequestClose: () => { this.closeImporter(); },
      darkMode: false,
      showImportLoadingStatus: false,
      customStyles: {
        "font-size": "15px",
        "base-spacing": "2rem",
        "border-radius": "8px",
        "color-primary": "#0B7D85",
        "color-primary-hover": "#086369",
        "color-text-on-primary": "#fff",
        "color-secondary": "#fff",
        "color-secondary-hover": "#EEF1F3",
        "color-text-on-secondary": "#213447",
        "color-text": "#213447",
        "color-importer-link": "#2A7AAF",
        "color-progress-bar": "#0B7D85",
      }
    });

    this.importer?.showModal();
  }

  closeImporter() {
    this.importer.close();
    document.body.removeChild(this.bulkImportContainer);
  }

  async importRegistrations(response, error) {
    this.closeImporter();

    const { data } = response;
    const { rows, num_error_rows } = data;

    console.log(data);

    this.continueButtonTarget.remove();
    this.containerTarget.innerHTML = `
      <div class="spinner-border" role="status">
        <span class="visually-hidden">Loading...</span>
      </div>
      <span class="single-line ms-3">Importing members...</span>
    `;

    const batches = rows.map((row, idx) => ({
      batch_id: idx + 1,
      membership_id: this.formIdValue,
      membership_tier_id: this.formTierIdValue,
      start_date: row.values.registration_date ?? null,
      values_attributes: Object.entries(row.values)
        .filter(([field_id, _value]) => field_id !== 'registration_date')
        .map(([field_id, value]) => ({
          field_id,
          value,
        })),
    }));

    const payload = { "registrations": batches };
    const csrfToken = $('meta[name="csrf-token"]').attr('content');
    const apiHeaders = {
      'Content-Type': 'application/json',
      'X-CSRF-Token': csrfToken,
    }

    try {
      const response = await fetch(
        '/api/v1/registrations/batch',
        {
          method: 'POST',
          body: JSON.stringify(payload),
          headers: apiHeaders,
        }
      );

      if (!response.ok) {
        const json = await response.json();
        console.log(json);
        this.containerTarget.innerHTML = `
          <i class="bi bi-bug danger-text me-2" style="font-size: 30px;"></i>
          <p class="body dark-6">
            Uh oh, we had a problem importing your registrations. Please try again or contact
            <a href="mailto:human@embolt.app">human@embolt.app</a> for assistance.
          </p>
        `;
        return;
      }

      const { count } = await response.json();

      this.containerTarget.innerHTML = `
        <i class="bi bi-check2-circle primary-6 me-2" style="font-size: 22px;"></i>
        <p class="dark-6 body mb-3">
          Successfully imported <strong>${count}</strong> registrations.
        </p>
      `;
    } catch (e) {
      this.containerTarget.innerHTML = `
        <i class="bi bi-bug danger-text me-2" style="font-size: 30px;"></i>
        <p class="body dark-6">
          Uh oh, we had a problem importing your registrations. Please try again or contact
          <a href="mailto:human@embolt.app">human@embolt.app</a> for assistance.
        </p>
      `;
    }

  }
}
