import { Controller } from '@hotwired/stimulus';
import { DirectUpload } from "@rails/activestorage"
import { APIClient } from '../../lib/api_client';

export default class extends Controller {
  static targets = [
    'image',
    'noImage',
    'removeButton',
    'bannerImageInput',
    'destroyImageInput',
  ];

  static values = {
    uploadUrl: String,
  };

  connect() {
    this.apiClient = new APIClient();
  }

  newImage(event) {
    const { target } = event;

    if (target.files.length < 1) {
      return;
    }

    const bannerImage = target.files[0];
    const uploader = new DirectUpload(bannerImage, this.uploadUrlValue);

    uploader.create((error, blob) => {
      if (error) {
        console.error(error);
      } else {
        this.bannerImageInputTarget.value = blob.signed_id;

        // If we previously removed an image before uploading the current image, make
        // sure we no longer mark the banner for deletion here.
        this.destroyImageInputTarget.value = "";

        this.displayImage(blob);
      }
    });
  }

  async displayImage(blob) {
    try {
      const imageUrl = await this.apiClient.images.fetchUrl(blob.signed_id);

      this.imageTarget.src = imageUrl;
      this.imageTarget.classList.remove('d-none');
      this.noImageTarget.classList.add('d-none');
    } catch (error) {
      console.error(error);
    }
  }

  removeImage(event) {
    this.imageTarget.classList.add('d-none');
    this.noImageTarget.classList.remove('d-none');

    // Add this back once we support cropping
    // this.cropButtonTarget.disabled = true;
    this.removeButtonTarget.disabled = true;

    // Flag image for deletion
    this.destroyImageInputTarget.value = "1";
  }
};
