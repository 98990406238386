import { Controller } from '@hotwired/stimulus';
import confetti from 'canvas-confetti';
import { APIClient } from '../../../lib/api_client';
import { fireConfetti } from '../../../lib/confetti_helper';

const VALIDATE_STEP = 'validate';
const PAYMENT_STEP = 'payment';

export default class extends Controller {
  static targets = [
    'registrationsForm',
    'disabledOverlay',
    'unknownError',
    'continueButton',
    'confirmation'
  ];

  static values = {
    eventId: String,
    eventTicketId: String,
    eventTicketQuantity: Number,
  }

  connect() {
    this.apiClient = new APIClient();
    this.currentStep = VALIDATE_STEP;

    if (typeof paypal === 'undefined') return;

    // Free tickets will not render the PayPal element, so we need to first check
    // if it exists before attempting to render the payment options.
    if (document.querySelector('#paypalButtons')) {
      const payPalWidget = paypal.Buttons({
        onInit: this.payPalOnInit.bind(this),
        createOrder: this.payPalCreateOrder.bind(this),
        onApprove: this.payPalOnApprove.bind(this),
      });

      payPalWidget.render('#paypalButtons');
    }

    this.confettiCannon = confetti.create(null, {
      resize: true,
      useWorker: true
    });

    // Temporaril prefill fields
    // this.registrationsFormTarget.querySelector(`[data-label="First name"]`).value = 'John';
    // this.registrationsFormTarget.querySelector(`[data-label="Last name"]`).value = 'Smith';
    // this.registrationsFormTarget.querySelector(`[data-label="Email"]`).value = 'john.smith@gmail.com';
  }

  // For tickets with no payment requirements, we'll perform the validation & submission
  // directly here in one step
  async submitFreeRegistrations() {
    const validated = await this.validateForm();

    if (validated) {
      const response = await this.submitRegistrations(null, false)
    }
  }

  async toggleStep() {
    // If payPal hasn't been initialized yet, don't do anything
    if (!this.payPalActions) return;

    this.clearInvalidFormState();

    let newStep = this.currentStep;

    switch (newStep) {
      case VALIDATE_STEP:
        // Only change the step if the form is valid. Invalid forms will have
        // their errors displayed to the user based on the API response.
        if (await this.validateForm()) {
          newStep = PAYMENT_STEP;
        }
        break;
      case PAYMENT_STEP:
        newStep = VALIDATE_STEP;
        break;
      default:
        throw new Error(`Invalid step provided: ${this.currentStep}`);
    }

    // If the step has change, toggle interactions to match the new step
    if (newStep !== this.currentStep) {
      this.toggleFormFields(newStep);
      this.toggleContinueButton(newStep);
      this.togglePaymentWidget(newStep);
      this.currentStep = newStep;
    }
  }

  // validate the form and continue to the payment step 
  async validateForm() {
    this.formData = new FormData(this.registrationsFormTarget);
    this.clearInvalidFormState();

    try {
      const response = await this.apiClient.eventRegistrations.validate({
        eventId: this.eventIdValue, formData: this.formData
      });

      switch (response.status) {
        // OK
        case 204:
          return true;
        // Captcha errors
        case 401:
          const { errors } = await response.json();
          const { captcha } = errors;

          this.showUnknownError(captcha);
        // Conflict error
        case 409:
          const { error } = await response.json();

          this.showUnknownError(error);
        // Validation errors
        case 422:
          const { errors: allErrors } = await response.json();

          this.markInvalidFieldsFromErrors(allErrors);
          break;
        // Internal server error
        case 500:
          console.error('Internal server error!', error);
          this.showUnknownError("Uh oh, looks like we're having trouble validating your information.");
          break;
      }
    } catch (error) {
      console.error('Unknown error!', error);
      this.unknownErrorTarget.classList.remove('d-none');
    }

    return false;
  }

  clearInvalidFormState() {
    // Hide any previous notice of an internal server error
    this.unknownErrorTarget.classList.add('d-none');

    // Hide all validation errors currently visible
    this.registrationsFormTarget.querySelectorAll(
      `[data-label]`
    ).forEach((field) => {
      field.classList.remove('is-invalid');
    });
  }

  markInvalidFieldsFromErrors(allErrors) {
    // Iterate over errors and highlight fields which need to be corrected
    Object.entries(allErrors).forEach(([regIdx, errors]) => {
      Object.entries(errors).forEach(([fieldLabel, validationErrors]) => {
        const filteredLabel = fieldLabel.replace(/^values\./, '');
        const fieldInput = this.registrationsFormTarget.querySelector(
          `[data-label="${filteredLabel}"][data-batch-id="${regIdx}"]`
        );

        fieldInput.classList.add('is-invalid');
      });
    });
  }

  showUnknownError(message) {
    this.unknownErrorTarget.querySelector('span[data-role="message"]').innerText = message;
    this.unknownErrorTarget.classList.remove('d-none');
  }

  toggleFormFields(step) {
    const elements = this.registrationsFormTarget.querySelectorAll('[data-role="formField"]');

    switch (step) {
      case VALIDATE_STEP:
        elements.forEach((field) => field.disabled = false);
        break;
      case PAYMENT_STEP:
        elements.forEach((field) => field.disabled = true);
        break;
      default:
        throw new Error(`Invalid step provided: ${step}`);
    }
  }

  toggleContinueButton(step) {
    const element = this.continueButtonTarget;

    switch (step) {
      case VALIDATE_STEP:
        element.innerText = 'Continue to payment';
        element.classList.remove('e-btn-outline-dark');
        element.classList.add('e-btn-primary');
        break;
      case PAYMENT_STEP:
        element.innerText = 'Edit details again';
        element.classList.remove('e-btn-primary');
        element.classList.add('e-btn-outline-dark');
        break;
      default:
        throw new Error(`Invalid step provided: ${step}`);
    }
  }

  togglePaymentWidget(step) {
    switch (step) {
      case VALIDATE_STEP:
        this.disabledOverlayTarget.classList.remove('d-none');
        break;
      case PAYMENT_STEP:
        this.disabledOverlayTarget.classList.add('d-none');
        this.payPalActions.enable();
        break;
      default:
        throw new Error(`Invalid step provided: ${step}`);
    }
  }

  async submitRegistrations(orderId, isPayment) {
    const errorMessage = isPayment ?
      "Your payment was successful, but we're having trouble finalizing your registration." :
      "We're having trouble finalizing your registration.";

    try {
      // this.formData is already set from the validateForm method, we can't reserialize
      // the form at this point because the form has been disabled because of the active
      // payment step. Instead, we rely on the exact form data we just validated.
      const response = await this.apiClient.eventRegistrations.batch({
        eventId: this.eventIdValue,
        formData: this.formData,
        orderId: orderId
      });

      switch (response.status) {
        case 204:
          this.element.closest('#modal').querySelector('.modal-header').classList.add('d-none');
          this.registrationsFormTarget.classList.add('d-none');
          this.confirmationTarget.classList.remove('d-none');
          setTimeout(() => fireConfetti(this.confettiCannon), 500);
          break;
        default:
          console.error('Unknown error!', error);
          this.showUnknownError(errorMessage);
      }
    } catch (error) {
      console.error('Unknown error!', error);
      this.showUnknownError(errorMessage);
    }
  }

  payPalOnInit(data, actions) {
    this.payPalActions = actions;
    this.payPalActions.disable();
  }

  async payPalCreateOrder(data, actions) {
    try {
      this.clearInvalidFormState();

      const response = await this.apiClient.eventRegistrations.createOrder({
        eventId: this.eventIdValue,
        eventTicketId: this.eventTicketIdValue,
        eventTicketQuantity: this.eventTicketQuantityValue
      });

      switch (response.status) {
        case 201:
          const { order } = await response.json();
          const { id } = order;
          return id;
        default:
          console.error('Unknown error!', response);
          this.showUnknownError("Uh oh, looks like we're having preparing your payment.");
      }
    } catch (error) {
      console.error('Unknown error!', response);
      this.showUnknownError("Uh oh, looks like we're having preparing your payment.");
    }
  }

  async payPalOnApprove(data, actions) {
    const { orderID: orderId } = data;

    await this.submitRegistrations(orderId, true);
  }
}